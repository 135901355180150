import {
    initialSingleValidation,
    checkValidation,
    validateRequiredString,
    validateRequiredNumber,
    validateRequiredObj,
    validateRequiredEmail,
    validSingleValidation,
} from "../../utils/validation";
import moment from "moment";

export const initialValidation = {
    name: initialSingleValidation,
    description: initialSingleValidation,
    site: initialSingleValidation,
    parameter: initialSingleValidation,
    operator: initialSingleValidation,
    value: initialSingleValidation,
    expiryDate: initialSingleValidation,
    frequency: initialSingleValidation,
    recipients: initialSingleValidation,
    turbine: {
        hubHeight: initialSingleValidation,
        turbineTypeId: initialSingleValidation,
        turbineModel: initialSingleValidation,
    },
    pitchConfiguration: {
        radioSelectionId: initialSingleValidation,
    },
    weatherWindowLengthOperator: initialSingleValidation,
    weatherWindowLengthValue: initialSingleValidation,
    parameters: [],
};

export const validate = (setValidation, alert, availableSites) => {
    const currentValidation = { ...initialValidation };

    // Reset the array
    currentValidation.parameters = [];
    // Loop through each parameter and validate
    alert.parameters.forEach((parameter) => {
        const parameterValidation = {
            parameter: validateRequiredObj(parameter.parameter),
            operator1: validateRequiredObj(parameter.operators[0]),
            value1: validateRequiredNumber(parameter.values[0]),
        };

        if (
            parameter.logic === null ||
            parameter.logic === undefined ||
            parameter.logic.id === 0
        ) {
            parameterValidation.logic = validSingleValidation;
            parameterValidation.operator2 = validSingleValidation;
            parameterValidation.value2 = validSingleValidation;
        } else {
            parameterValidation.logic = validateRequiredObj(parameter.logic);
            parameterValidation.operator2 = validateRequiredObj(
                parameter.operators[1]
            );
            parameterValidation.value2 = validateRequiredNumber(
                parameter.values[1]
            );
        }

        currentValidation.parameters.push(parameterValidation);
    });

    currentValidation.name = validateRequiredString(alert.name);
    currentValidation.site = validateRequiredSite(alert.site, availableSites);
    currentValidation.expiryDate = validateExpiryDate(alert.expiryDate);
    currentValidation.frequency = validateRequiredObj(alert.frequency);
    currentValidation.recipients = validateRecipients(alert.recipients);
    currentValidation.description = validateRequiredString(alert.description);
    currentValidation.weatherWindowLengthOperator = validateRequiredObj(
        alert.weatherWindowLengthOperator
    );
    currentValidation.weatherWindowLengthValue = validateRequiredNumber(
        alert.weatherWindowLengthValue
    );

    const valid = checkValidation(currentValidation);

    if (!valid) {
        setValidation(currentValidation);
    }
    return valid;
};

const validateRequiredSite = (site, availableSites) => {
    if (site) {
        if (availableSites.some((x) => x.id === site.id)) {
            return validSingleValidation;
        } else {
            return {
                valid: false,
                message:
                    "Associated site has been deleted. Please add valid site.",
            };
        }
    }
    return {
        valid: false,
        message: "Required",
    };
};

const validateExpiryDate = (expiryDate) => {
    if (moment(expiryDate).isBefore(moment(), "day")) {
        return {
            valid: false,
            message: "Cannot be earlier than current date",
        };
    } else if (moment(expiryDate).isAfter(moment().add(5, "y").toDate())) {
        return {
            valid: false,
            message: "Cannot be more than 5 years in the future",
        };
    } else {
        return validSingleValidation;
    }
};

const validateRecipients = (recipients) => {
    return recipients.map((recipient) => {
        return validateRequiredEmail(recipient);
    });
};
